<template>
  <div>
    <vs-card>
      <div>
        <h3 style="margin-left: 1%" vs-align="center">
          Servetel Agent Management
        </h3>
        <br />
        <vs-row>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-w="6"
            vs-align="center"
          >
            <vs-select
              class="selectExample"
              label="City"
              v-model="selected_city"
            >
              <vs-select-item
                :key="index"
                :value="item.text"
                :text="item.text"
                v-for="(item, index) in cities"
              />
            </vs-select>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-w="6"
            vs-align="center"
            v-if="admin_visibility"
          >
            <vs-button
              color="primary"
              icon="add"
              size="medium"
              @click="OpenAddPopup()"
            >
            </vs-button>
          </vs-col>
        </vs-row>
      </div>
      <vs-table
        :data="city_wise_spocs"
        style="margin-top: 2%; color: gray"
      >
        <template slot="header" vs-align="center"> </template>
        <template slot="thead">
          <vs-th> Spoc Name </vs-th>
          <vs-th> Email </vs-th>
          <vs-th> Contact Number </vs-th>
          <vs-th> Last Updated </vs-th>
          <!-- <vs-th> Edit </vs-th> -->
          <vs-th v-if="admin_visibility"> Delete </vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr v-for="(tr, i) in data" :key="i">
            <vs-td :data="data[i].name">
              {{ data[i].name }}
            </vs-td>
            <vs-td :data="data[i].email_id"> 
              {{ data[i].email_id }} 
            </vs-td>
            <vs-td :data="data[i].contact_number">
              {{ data[i].contact_number }}
            </vs-td>
            <vs-td :data="data[i].last_updated">
              {{ data[i].last_updated }}
            </vs-td>
            <vs-td :data="data[i].id" v-if="admin_visibility">
              <vs-button
                @click="deletePopup(tr)"
                color="primary"
                icon="delete"
                size="small"
              ></vs-button>
            </vs-td>
          </vs-tr>
          <div>
            <vs-popup
              class="holamundo"
              title="User Delete"
              :active.sync="showPopup"
            >
              <vs-row>
                <vs-col vs-w="12">
                  <p>
                    Are you sure you want to delete {{ popup_object.name }}?
                  </p>
                </vs-col>
              </vs-row>
              <vs-row>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-end"
                  vs-align="right"
                  vs-w="12"
                >
                  <vs-button
                    color="primary"
                    @click="userDeleteBtn()"
                    size="small"
                  >
                    Yes
                  </vs-button>
                  <vs-button
                    @click="showPopup = false"
                    color="primary"
                    size="small"
                    style="margin-left: 2%"
                    >NO</vs-button
                  >
                </vs-col>
              </vs-row>
            </vs-popup>
          </div>
        </template>
      </vs-table>
      <!-- Add Button -->
      <div>
        <vs-popup
          id="popupSize"
          title="Add Servetel Agent"
          :active.sync="popupActivoAdd"
        >
          <vs-row>
            <vs-col vs-justify="flex-start" vs-align="center" vs-w="9">
              <v-select
                style="position: fixed; z-index: 900; width: 400px"
                :options="cm_spocs"
                label="name_city"
                v-model="selected_spoc_object"
              ></v-select>
              <!-- <vs-select autocomplete style="width: 450px; " class="selectExample"  v-model="selected_spoc">
              <vs-select-item
                :key="index"
                :value="item.id"
                :text="item.name_city"
                v-for="(item, index) in cm_spocs"
              /> 
            </vs-select>  -->
            </vs-col>
            <vs-col
              vs-justify="flex-center"
              vs-align="center"
              vs-w="2"
              style="margin-left: 15px"
            >
              <vs-button
                @click="addData()"
                size="medium"
                color="dark"
                type="gradient"
              >
                Add
              </vs-button>
            </vs-col>
          </vs-row>
        </vs-popup>
      </div>
    </vs-card>
  </div>
</template>
  
<script>
import constants from "../../constants.json";
import axios from "axios";
import vSelect from "vue-select";
export default {
  components: {
    "v-select": vSelect,
  },
  data: () => ({
    admin_visibility:true,
    cm_spocs: [],
    select1Normal: "",
    showPopup: false,
    popupActivoAdd: false,
    selected_spoc: null,
    selected_spoc_object: "",
    selected_city: "Ahmedabad",
    popup_object: {
      user_id: null,
      name: "",
    },
    get_data: [],
    delete_id: null,
    cities: [
      { text: "Ahmedabad" },
      { text: "Bangalore" },
      { text: "Chennai" },
      { text: "Delhi" },
      { text: "Ernakulam" },
      { text: "Hyderabad" },
      { text: "Kolkata" },
      { text: "Mumbai" },
      { text: "Pune" },
      { text: "India" }
    ],
    users: [],
    get_allapi: [],
    get_add: [{}],
    get_email: "",
    get_allValue: [],
    spoc_names: [],
    ids: [],
    city_wise_spocs: [],
  }),
  watch: {
    selected_spoc_object(val) {
      this.selected_spoc = null;
      this.selected_spoc = val.id;
    },
    selected_city(val) {
      console.log(val);
      this.getCityWiseSpoc();
    },
  },
  mounted() {
    this.getServetelAgents();
    const id = localStorage.getItem('user_id')
    if (id == 39 || id == 132 || id == 937) {
        this.admin_visibility = true
      }else{
        this.admin_visibility = false
      }

    // this.getCityWiseSpoc();
  },
  created(){
    const id = localStorage.getItem('user_id')
    if (id == 39 || id == 132 || id == 937) {
        this.admin_visibility = true
      }else{
        this.admin_visibility = false
      }
  },
  methods: {
    OpenAddPopup() {
      this.selected_spoc = null;
      this.getCMSpocs();
      this.popupActivoAdd = true;
      document.getElementById("popupSize").children[1].style.width = "40%";
    },
    getCityWiseSpoc() {
      this.city_wise_spocs = [];
      for (let i = 0; i < this.users.length; i++) {
        const element = this.users[i];
        if (element.city === this.selected_city) {
          this.city_wise_spocs.push(element);
        }
        if (this.selected_city === "Ernakulam") {
          if (element.city === "Kochi") {
            this.city_wise_spocs.push(element);
          }
        }
      }
    },
    getServetelAgents() {
      axios
        .get(`${constants.SERVER_API}getServetelAgents`, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log("getServetelAgents", response);
          this.users = response.data;
          this.getCityWiseSpoc();
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getCMSpocs() {
      axios
        .get(`${constants.SERVER_API}getCM`, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log("getServetelAgents", response);
          this.cm_spocs = response.data.data;
          for (let i = 0; i < this.cm_spocs.length; i++) {
            const element = this.cm_spocs[i];
            element.name_city = element.first_name + " " + element.last_name + " -  " + element.user_tag + " " + "-" + " " + element.city;
          }
          // this.getCityWiseSpoc();
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    deletePopup(row_data) {
      this.popup_object.user_id = row_data.user_id;
      this.popup_object.name = row_data.name;
      console.log("delete id", this.popup_object.user_id);
      this.showPopup = true;
    },
    userDeleteBtn() {
      let delete_agent = {
        user_id: this.popup_object.user_id,
      };
      console.log("delete agent", delete_agent);
      let url = `${constants.SERVER_API}removeServetelAgent`;
      axios
        .post(url, delete_agent, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log("removeServetelAgent", response);
          if (response.data.status == "success") {
            this.$vs.notify({
              text: response.data.message,
              color: "success",
            });
          } else {
            this.$vs.notify({
              text: response.message,
              color: "danger",
            });
          }
          this.getServetelAgents();
          this.showPopup = false;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    addData() {
      let add_agent = {
        user_id: this.selected_spoc,
      };
      let url = `${constants.SERVER_API}addServetelAgent`;
      axios
        .post(url, add_agent, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log("upDateServetelAgents", response);
          if (response.data.status == "success") {
            this.$vs.notify({
              text: response.data.message,
              color: "success",
            });
          } else {
            this.$vs.notify({
              text: response.data.message,
              color: "danger",
            });
          }
          this.getServetelAgents();
          this.popupActivoAdd = false;
          this.selected_spoc_object = "";
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
  },
};
</script>
<style scoped>
.con-vs-popup .vs-popup {
  width: 51% !important;
}
.popupSize {
  width: 100%;
}
</style>